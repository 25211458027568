import $ from "jquery";
import * as eva from 'eva-icons';
import Cookies from 'js-cookie';
/* exported slick */
import slick from 'slick-slider';

export default {
	init() {
		// JavaScript to be fired on all pages
	},
	finalize() {
		if($('.mobile-nav').hasClass('open')) {
			$('nav.mobile-nav li a').each(function (i) { $(this).attr('tabindex', i + 1); });;
		} else {
			$('nav.mobile-nav li a').attr('tabindex', -1);
		}
		$('.toggle-menu').on('click', function() {
			$('.toggle-menu').toggleClass('active');
			$('.mobile-nav').toggleClass('open');
			$('main').toggleClass('overflow-y-hidden h-0');
		})

		function subMenuMobile() {
			let $hasSubMenu = $('.mobile nav li.menu-item-has-children');
			$hasSubMenu.append('<button class="toggle-sub-menu" tabindex=-1><i data-eva="chevron-down-outline" data-eva-fill="currentColor" aria-label="toggle-submenu"></i></button>');
		}
		subMenuMobile();

		$('.toggle-sub-menu').on('click', function() {
			console.log($(this));
			$(this).parent('li.menu-item-has-children').toggleClass('open');
		});


		//get correct screenheight
		function vh() {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		vh();
		window.addEventListener('resize', () => {
			vh();
		});

		//Cookies
		function checkCookies() {
			let height = $('.cookie .box').height();
			height = height*2;
			if(!Cookies.get('terms')) {
				setTimeout( function() {
					$('.cookie .box').addClass('slide-in');
					$('footer').css('margin-bottom', height);
				}, 1000 );
				$(window).on('scroll', function() {
						$('.cookie .box').addClass('slide-in');
						$('footer').css('margin-bottom', height);
					});
				$('.cookie .approve').click( function() {
					Cookies.set('terms', 'approved');
					$('.cookie .box').removeClass('slide-in');
					$('footer').css('margin-bottom', '0');
					setTimeout(function() {$('.cookie').addClass('hidden')}, 1000);
				});
				$('.cookie .close').click( function() {
					$('.cookie .box').removeClass('slide-in');
					$('footer').css('margin-bottom', '0');
					setTimeout(function() {$('.cookie').addClass('hidden')}, 1000);
				});
			} else {
				$('.cookie').addClass('hidden');
				$('footer').css('margin-bottom', '0');
			}
		};
		setTimeout(checkCookies(), 1000);

		//Slider row
		if($('body').hasClass('template-rows')) {
			let $slider = $('.template-rows .slider.slick');
			$slider.slick({
				slidesToShow: 1,
				autoplay: true,
  			autoplaySpeed: 5000,
				fade: true,
				arrows: false,
			});
		}

		//Generate icons
		eva.replace()
	},
};
