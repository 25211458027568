import $ from "jquery";
import simpleLightbox from "simplelightbox";

export default {
	init() {
		// JavaScript to be fired on all pages
	},
	finalize() {
		//Gallery in lightbox
		if($('.gallery').children().length > 0) {
			let gallery = new SimpleLightbox('.gallery a', { /* options */ });
			gallery.on('show.simplelightbox', function () {
			});
			gallery.on('error.simplelightbox', function (e) {
				console.log(e); // Some usefull information
			});
		}

		//Visa mer
		$('.show-more-button').on('click', function() {
			var $this = $(this);
			$this.toggleClass('active');
			$('.bostadsinfo .show-more').toggleClass('active');
			if($this.hasClass('active')){
				$this.html('Visa mindre')
			} else {
				$this.html('Visa mer');
			}
		})

		//ACF Google maps
			function initMap( $el ) {
				// Find marker elements within map.
				var $markers = $el.find('.marker');

				// Create gerenic map.
				var mapArgs = {
					zoom        : $el.data('zoom') || 16,
					mapTypeId   : google.maps.MapTypeId.ROADMAP
				};
				var map = new google.maps.Map( $el[0], mapArgs );

				// Add markers.
				map.markers = [];
				$markers.each(function(){
					initMarker( $(this), map );
				});

				// Center map based on markers.
				centerMap( map );

				// Return map instance.
				return map;
			}
			function initMarker( $marker, map ) {
				// Get position from marker.
				var lat = $marker.data('lat');
				var lng = $marker.data('lng');
				var latLng = {
					lat: parseFloat( lat ),
					lng: parseFloat( lng )
				};

				// Create marker instance.
				var marker = new google.maps.Marker({
					position : latLng,
					map: map
				});

				// Append to reference for later use.
				map.markers.push( marker );

				// If marker contains HTML, add it to an infoWindow.
				if( $marker.html() ){
					// Create info window.
					var infowindow = new google.maps.InfoWindow({
						content: $marker.html()
					});

					// Show info window when marker is clicked.
					google.maps.event.addListener(marker, 'click', function() {
						infowindow.open( map, marker );
					});
				}
			}

			function centerMap( map ) {
				// Create map boundaries from all map markers.
				var bounds = new google.maps.LatLngBounds();
				map.markers.forEach(function( marker ){
					bounds.extend({
						lat: marker.position.lat(),
						lng: marker.position.lng()
					});
				});

				// Case: Single marker.
				if( map.markers.length == 1 ){
					map.setCenter( bounds.getCenter() );

				// Case: Multiple markers.
				} else{
					map.fitBounds( bounds );
				}
			}

			$(document).ready(function(){
				$('.acf-map').each(function(){
					/* exported map */
					var map = initMap( $(this) );
				});
			});

	},
};
