import $ from "jquery";
import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';

export default {
	init() {
		// JavaScript to be fired on all pages
	},
	finalize() {

		jQueryBridget('masonry', Masonry, $);

		$('.list-posts').masonry({
			// options
			itemSelector: '.post-container',
			columnWidth: '.grid-sizer',
			percentPosition: true
		});

	},
};
