import $ from 'jquery';
import Router from './util/Router';
import common from './routes/common';
import templateAktuellt from './routes/aktuellt';
import templateFastigheter from './routes/fastigheter';
import templateLedigt from './routes/ledigt';
import singleLedigBostad from './routes/ledig-bostad';
import archive from './routes/archive';
import home from './routes/home';

import './styles/style.less';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** Other pages */
	templateAktuellt,
	templateFastigheter,
	singleLedigBostad,
	templateLedigt,
	archive
});

/** Load Events */
$(document).ready(() => routes.loadEvents());
